/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~bootstrap";

// Custom Variables
$blue: #23c3e7;
$green: #97d101;
$pink: #ff75a5;

// Fonts

@font-face {
  font-family: 'Borden';
  src: url("assets/fonts/Borden.ttf");
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url("assets/fonts/Montserrat-Bold_3.otf");
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url("assets/fonts/Montserrat-SemiBold_2.otf");
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  src: url("assets/fonts/Montserrat-ExtraLight_1.otf");
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url("assets/fonts/Montserrat-Medium_1.otf");
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  src: url("assets/fonts/Montserrat-Thin_1.otf");
}
@font-face {
  font-family: 'Myriad Pro';
  src: url("assets/fonts/MyriadPro-Regular.otf");
}
@font-face {
  font-family: 'DK Crayon Crumble';
  src: url("assets/fonts/editorFonts/DK-Crayon-Crumble.otf");
}
@font-face {
  font-family: 'A Arrange Signature';
  src: url("assets/fonts/editorFonts/aArrangeSignature.otf");
}
@font-face {
  font-family: 'Cheddar Gothic Stencil';
  src: url("assets/fonts/editorFonts/CheddarGothic-Stencil.otf");
}
@font-face {
  font-family: 'Free Pen';
  src: url("assets/fonts/editorFonts/Free-Pen.otf");
}
@font-face {
  font-family: 'Mastura';
  src: url("assets/fonts/editorFonts/Mastura.otf");
}
.borden{
  font-family: 'Borden';
}
.montserrat{
  font-family: 'Montserrat';
}
.myriad{
  font-family: 'Myriad Pro';
}
.crayon{
  font-family: 'DK Crayon Crumble';
}
.w-normal{
  font-weight: normal;
}
.w-bold{
  font-weight: bold;
}
.w-100{
  font-weight: 100;
}
.w-200{
  font-weight: 200;
}
a{
  color: $pink;
  font-family: 'Myriad Pro';
  font-weight: bold;
  text-decoration: none;
  &:hover, &:focus, &:active{
    &:not(.button){
      color: darken($pink, 10%) !important;
    }
  }
  &.upper{
    text-transform: uppercase;
  }
  &.button{
    background-color: #ff75a5;
    color: white;
    padding: 0 0.5rem;
    border-radius: 12px;
    border: 1px solid black;

  }
}
button{
  background-color: $green;
  color: white;
  border-radius: 100px;
  width: 100%;
  border: 1px solid black;
  font-family: 'Borden';
  font-size: 58px;
  line-height: 50px;
  padding-bottom: 16px;
  &.w-auto{
    padding-left: 30px;
    padding-right: 30px;
  }
  &.back{
    background-color: $blue;
    font-size: 30px;
    line-height: 30px;
    position: absolute;
    top: 20px;
    left: 20px;
    width: auto;
    padding: 0 20px 5px;
  }
  &.profile{
    background-color: $blue;
    font-size: 30px;
    line-height: 30px;
    position: absolute;
    top: 20px;
    right: 20px;
    width: auto;
    padding: 0 20px 5px;
  }
  &.logout{
    background-color: $blue;
    font-size: 30px;
    line-height: 30px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: auto;
    padding: 0 20px 5px;
  }
  &.toPorttoolio{
    background-color: $blue;
    font-size: 30px;
    line-height: 30px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: auto;
    padding: 0 20px 5px;
  }
  &.delete{
    background-color: $pink;
  }
  &[disabled]{
    filter: grayscale(1);
  }
}
input[type="checkbox"]{
  background-color: $blue;
}
.disable-scroll{
    >.grid-wrapper{
      height: 100%;
    }
}
.swiper-wrapper{
  width: auto !important;
  ion-slide{
    width: 100% !important;
  }
}
.bg-welcome{
  background: url("/assets/imgs/backgrounds/App_Welcome_no_logo.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.bg-login{
  background: url("/assets/imgs/backgrounds/App_Login_no_logo.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.bg-new-open{
  background: url("/assets/imgs/backgrounds/new project open project 2_New-open.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.bg-lantern{
  background: url("/assets/imgs/backgrounds/main screen2_Group Test.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.bg-editor{
  background: url("/assets/imgs/backgrounds/site editor2_File.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.bg-child-select{
  background: url("/assets/imgs/backgrounds/child-select.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.bg-design-select{
  background: url("/assets/imgs/backgrounds/design-select.jpg");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.plt-desktop{
  .alert-radio-label{
    font-size: 26px;
  }
  .alert-button-inner{
    font-size: 26px;
  }
  .sc-ion-alert-md-h{
    --max-width: 500px;
    --min-width: 350px;
  }
}
.editor{
  .canvas-container{
    position: absolute !important;
    top: 0;
    left: 0;
  }
}
app-child-detail{
  overflow-y: auto !important;
}
button.alert-button{
  width: auto !important;
}


#canvas-wrapper-2{
  .canvas-container{
    position: relative !important;
    margin: 0 auto;
  }
}
@media screen and (max-device-width: 800px){
}

@media screen and (max-device-width: 1300px){
  .hiddenAllHandheld{
    display: none !important;
  }
}
